/**
 * @file Seasons - Subjects - UpdateBulkPopup
 *
 * @author jessie129j <jessie129j@gmail.com>
 *
 * -------------------------------------------------------
 *
 * IN PRODUCTION
 *
 * -------------------------------------------------------
 *
 * IN MAINTENANCE
 *
 * -------------------------------------------------------
 *
 * IN DEVELOPMENT
 *
 * -------------------------------------------------------
 *
 * DEPRECATED
 *
 * -------------------------------------------------------
 *
 * NOTES
 *
 * @version 1.0
 *
 */

import React, { useState, useRef, useEffect } from "react";
import * as xlsx from "xlsx";

import style from "style/pages/admin/schools.module.scss";

// components
import Button from "components/button/Button";
import Callout from "components/callout/Callout";
import Textarea from "components/textarea/Textarea";

type Props = {};

const description = `1. 엑셀을 열어 교과목 헤더를 A1셀부터 입력합니다.
2. 교과목 항목을 B1셀부터 입력합니다.`;

const exampleData = [
  {
    구분: "국어",
    교과목명: "공통국어1",
  },
  {
    구분: "국어",
    교과목명: "공통국어2",
  },
  {
    구분: "국어",
    교과목명: "화법과 언어",
  },
  {
    구분: "국어",
    교과목명: "독서와 작문",
  },
  {
    구분: "국어",
    교과목명: "문학",
  },
  {
    구분: "국어",
    교과목명: "주제 탐구 독서",
  },
  {
    구분: "국어",
    교과목명: "문학과 영상",
  },
  {
    구분: "국어",
    교과목명: "직무 의사소통",
  },
  {
    구분: "수학",
    교과목명: "공통수학1",
  },
  {
    구분: "수학",
    교과목명: "공통수학2",
  },
  {
    구분: "수학",
    교과목명: "대수",
  },
  {
    구분: "수학",
    교과목명: "미적분Ⅰ",
  },
  {
    구분: "수학",
    교과목명: "미적분Ⅱ",
  },
  {
    구분: "수학",
    교과목명: "확률과 통계",
  },
  {
    구분: "수학",
    교과목명: "기하",
  },
  {
    구분: "수학",
    교과목명: "경제 수학",
  },
  {
    구분: "수학",
    교과목명: "인공지능 수학",
  },
  {
    구분: "수학",
    교과목명: "직무 수학",
  },
  {
    구분: "영어",
    교과목명: "기본영어1",
  },
  {
    구분: "영어",
    교과목명: "기본영어2",
  },
  {
    구분: "영어",
    교과목명: "영어Ⅰ",
  },
  {
    구분: "영어",
    교과목명: "영어Ⅱ",
  },
  {
    구분: "영어",
    교과목명: "영어 독해와 작문",
  },
  {
    구분: "영어",
    교과목명: "영미 문학 읽기",
  },
  {
    구분: "영어",
    교과목명: "영어 발표와 토론",
  },
  {
    구분: "영어",
    교과목명: "직무 영어",
  },
  {
    구분: "영어",
    교과목명: "심화영어",
  },
  {
    구분: "영어",
    교과목명: "심화 영어 톡해와 작문",
  },
  {
    구분: "사회",
    교과목명: "한국사1",
  },
  {
    구분: "사회",
    교과목명: "한국사2",
  },
  {
    구분: "사회",
    교과목명: "통합사회1",
  },
  {
    구분: "사회",
    교과목명: "통합사회2",
  },
  {
    구분: "사회",
    교과목명: "세계시민과 지리",
  },
  {
    구분: "사회",
    교과목명: "세계사",
  },
  {
    구분: "사회",
    교과목명: "사회와 문화",
  },
  {
    구분: "사회",
    교과목명: "한국지리 탐구",
  },
  {
    구분: "사회",
    교과목명: "도시의 미래 탐구",
  },
  {
    구분: "사회",
    교과목명: "정치",
  },
  {
    구분: "사회",
    교과목명: "경제 수학",
  },
  {
    구분: "사회",
    교과목명: "법과 사회",
  },
  {
    구분: "사회",
    교과목명: "국제 관계의 이해",
  },
  {
    구분: "과학",
    교과목명: "통합과학1",
  },
  {
    구분: "과학",
    교과목명: "통합과학2",
  },
  {
    구분: "과학",
    교과목명: "과학탐구 실험",
  },
  {
    구분: "과학",
    교과목명: "물리학",
  },
  {
    구분: "과학",
    교과목명: "화학",
  },
  {
    구분: "과학",
    교과목명: "생명과학",
  },
  {
    구분: "과학",
    교과목명: "지구과학",
  },
  {
    구분: "과학",
    교과목명: "역학과 에너지",
  },
  {
    구분: "과학",
    교과목명: "전자기와 빛",
  },
  {
    구분: "과학",
    교과목명: "물질과 에너지",
  },
  {
    구분: "과학",
    교과목명: "화학반응의 세계",
  },
  {
    구분: "과학",
    교과목명: "생물의 유전",
  },
  {
    구분: "과학",
    교과목명: "세포와 물질대사",
  },
  {
    구분: "과학",
    교과목명: "지구시스템과학",
  },
  {
    구분: "과학",
    교과목명: "행성우주과학",
  },
  {
    구분: "체육",
    교과목명: "체육Ⅰ",
  },
  {
    구분: "체육",
    교과목명: "체육Ⅱ",
  },
  {
    구분: "체육",
    교과목명: "운동과 건강Ⅰ",
  },
  {
    구분: "체육",
    교과목명: "운동과 건강Ⅱ",
  },
  {
    구분: "체육",
    교과목명: "(미니)체육 탐구",
  },
  {
    구분: "예술",
    교과목명: "음악",
  },
  {
    구분: "예술",
    교과목명: "미술",
  },
  {
    구분: "예술",
    교과목명: "연극",
  },
  {
    구분: "예술",
    교과목명: "음악 연주와 창작",
  },
  {
    구분: "예술",
    교과목명: "음악 감상과 비평",
  },
  {
    구분: "예술",
    교과목명: "미술 창작",
  },
  {
    구분: "예술",
    교과목명: "미술 감상과 비평",
  },
  {
    구분: "실과(기술·가정)",
    교과목명: "기술가정",
  },
  {
    구분: "실과(기술·가정)",
    교과목명: "가족과 가정생활",
  },
  {
    구분: "정보",
    교과목명: "정보",
  },
  {
    구분: "정보",
    교과목명: "로봇과 공학세계",
  },
  {
    구분: "정보",
    교과목명: "인공지능 기초",
  },
  {
    구분: "정보",
    교과목명: "데이터과학",
  },
  {
    구분: "제2외국어",
    교과목명: "독일어",
  },
  {
    구분: "제2외국어",
    교과목명: "일본어",
  },
  {
    구분: "제2외국어",
    교과목명: "프랑스어",
  },
  {
    구분: "제2외국어",
    교과목명: "러시아어",
  },
  {
    구분: "제2외국어",
    교과목명: "스페인어",
  },
  {
    구분: "제2외국어",
    교과목명: "아랍어",
  },
  {
    구분: "제2외국어",
    교과목명: "중국어",
  },
  {
    구분: "제2외국어",
    교과목명: "베트남어",
  },
  {
    구분: "진로와 직업",
    교과목명: "진로와 직업",
  },
  {
    구분: "교양",
    교과목명: "생태와 환경",
  },
  {
    구분: "교양",
    교과목명: "인간과 철학",
  },
  {
    구분: "교양",
    교과목명: "삶과 종교",
  },
  {
    구분: "교양",
    교과목명: "논리와 사고",
  },
  {
    구분: "교양",
    교과목명: "인간과 심리",
  },
  {
    구분: "교양",
    교과목명: "교육의 이해",
  },
  {
    구분: "교양",
    교과목명: "보건",
  },
];

const exampleDownload = () => {
  const ws = xlsx.utils.json_to_sheet(exampleData);
  const wb = xlsx.utils.book_new();

  xlsx.utils.book_append_sheet(wb, ws, "Sheet1");
  xlsx.writeFile(wb, `example.xlsx`);
};

function Help(props: Props) {
  return (
    <div className={style.popup}>
      <div style={{ marginTop: "24px" }}>
        <Callout
          type="info"
          title="도움말"
          showIcon
          child={
            <Textarea
              defaultValue={description}
              rows={3}
              style={{
                backgroundColor: "rgba(0, 0, 0, 0)",
                margin: "0px",
                padding: "0px",
              }}
              disabled
            />
          }
        />
      </div>
      <div>
        <Button
          type={"ghost"}
          onClick={() => {
            exampleDownload();
          }}
          style={{
            borderRadius: "4px",
            height: "32px",
            boxShadow: "rgba(0, 0, 0, 0.1) 0px 1px 2px 0px",
            marginTop: "24px",
          }}
        >
          양식 다운로드
        </Button>
      </div>
    </div>
  );
}

export default Help;
