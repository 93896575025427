import React from 'react'

type Props = {}

const List = (props: Props) => {
  return (
    <div>List</div>
  )
}

export default List