import React from 'react'

type Props = {}

const Course = (props: Props) => {
  return (
    <div>Course</div>
  )
}

export default Course